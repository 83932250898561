import React, { useState, useEffect, useCallback } from "react"
// import SelectDatepicker from "react-select-datepicker"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"
import Cookies from "universal-cookie"
import { animateScroll as scroll } from "react-scroll"
import { Helmet } from "react-helmet"

import Layout from "../../components/SnickersNFLComponenets/components/layout"
import Thankyou from "../../components/SnickersNFLComponenets/components/thankyou"
// import Agegate from "../components/agegate"

const EnterPage = () => {
  // const [ageGateShow, setAgeGateShow] = useState(true)
  const [birthValue, setBirthValue] = useState(null)
  const [submitted, setSubmitted] = useState(false)

  let ageCookie = new Cookies()
  useEffect(() => {
    if (!ageCookie.get("isOldEnough")) {
      navigate("/snickers-nfl/agegate")
    }
  }, [])
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const onSubmit = data => {
    // let birthday
    // if (birthValue !== null) {
    //   const months = [
    //     "January",
    //     "February",
    //     "March",
    //     "April",
    //     "May",
    //     "June",
    //     "July",
    //     "August",
    //     "September",
    //     "October",
    //     "November",
    //     "December",
    //   ]
    //   const month = months[birthValue.getMonth()]
    //   const day = birthValue.getDate()
    //   const year = birthValue.getFullYear()
    //   birthday = `${month}-${day}-${year}`
    // }

    // console.log(birthday)

    // data.birthday = birthday

    // console.log(data)
    setSubmitted(true)
    scroll.scrollToTop()
    reset()
  }
  const onDateChange = useCallback(date => {
    setBirthValue(date)
  }, [])
  return (
    <Layout>
      <Helmet>
        <link rel="stylesheet" href="../../SnickersNFL/css/app.css" />
        <link rel="stylesheet" href="../../SnickersNFL/css/responsive.css" />
      </Helmet>
      {!submitted ? (
        <>
          <div className="page-heading">
            <h1 className="text-uppercase text-lg">
              <span className="d-md-block">Enter for a</span> chance to win
            </h1>
            <p className="text-lg knockoutLiteweightRegular">
              Enter your info below for a chance to win a flyaway trip for you
              and a friend to next year's Super Bowl LVII in Arizona. Limit one
              (1) entry per person, per day. Ends 2/14/2022.
            </p>
          </div>

          <div className="text-start entry-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  First Name<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="First Name"
                  name="First Name"
                  {...register("firstName", { required: true })}
                />
                {errors.firstName && (
                  <span className="input-error">First Name is required</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  Last Name<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Last Name"
                  name="Last Name"
                  {...register("lastName", { required: true })}
                />
                {errors.lastName && (
                  <span className="input-error">Last Name is required</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  Email<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="Email"
                  name="Email"
                  {...register("Email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Please enter a valid email",
                    },
                  })}
                />
                {errors.Email && (
                  <span className="input-error">{errors.Email.message}</span>
                )}
              </div>
              {/* <div className="form-group">
                <label htmlFor="birthdate" className="form-label">
                  Birthdate<span>*</span>
                </label>
                <SelectDatepicker
                  id="birthdate"
                  value={birthValue}
                  onDateChange={onDateChange}
                  minDate={new Date(1900, 0, 1)}
                  maxDate={new Date()}
                  showLabels={false}
                  labels={{ year: "YYYY", month: "MM", day: "DD" }}
                />
              </div> */}
              <div className="form-group">
                <label htmlFor="phoneNumber" className="form-label">
                  Phone Number<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phoneNumber"
                  placeholder="Phone Number"
                  name="Phone Number"
                  {...register("phoneNumber", { required: true })}
                />
                {errors.phoneNumber && (
                  <span className="input-error">Phone Number is required</span>
                )}
              </div>

              <div className="form-check-hldr">
                <div className="form-check">
                  <input
                    id="marsPrivacyPolicy"
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    {...register("marsPrivacyPolicy", { required: true })}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="marsPrivacyPolicy"
                  >
                    I agree to the Mars Privacy Policy and to be bound by the
                    Official Rules and the decisions of the Sponsor.
                  </label>
                  {errors.marsPrivacyPolicy && (
                    <span className="input-error">Consent is required</span>
                  )}
                </div>
                <div className="form-check">
                  <input
                    id="marketingCommunications"
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    {...register("marketingCommunications")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="marketingCommunications"
                  >
                    I would like to receive future marketing communications from
                    SNICKERS® and other Mars Wrigley’s brands via the email
                    provided. You can withdraw this consent at any time.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    id="nflPrivacyPolicy"
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    {...register("nflPrivacyPolicy")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="nflPrivacyPolicy"
                  >
                    I agree to receive future promotional emails from the
                    National Football League and agree to NFL Privacy Policy.
                    You can withdraw this consent at any time.
                  </label>
                </div>
              </div>

              <div className="text-center btn-hldr">
                <input
                  type="submit"
                  value="Submit"
                  className="btn btn-primary text-uppercase rounded-0"
                />
              </div>
            </form>
          </div>
        </>
      ) : (
        <Thankyou setSubmitted={setSubmitted} />
      )}
    </Layout>
  )
}

export default EnterPage
